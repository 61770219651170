export const style = {
      position: "absolute",
      top: "40%",
      left: "33%",
      transform: "translate(-50%, -50%)",
      width: "150mm",
      minHeight: "150mm",
      bgcolor: "background.paper",
      border: "none",
      borderRadius: "5px",
      background: "linear-gradient(pink,rgba(244, 241, 241, 0.75))",
      boxShadow: 2,
      p: 5,
    };
    